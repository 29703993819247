import apiClient from '@/services/axios'

export async function getData(page, perPage, columns, filter, order) {
  filter.find(obj => obj.Column === 'AdType')
  const desiredObject = filter.find(obj => obj.Column === 'AdType' && obj.Values === 0)
  if (desiredObject !== undefined && desiredObject) {
    filter = filter.filter(obj => obj !== desiredObject)
  }
  return apiClient.post('/lib/crids', {
    Page: page,
    PerPage: perPage,
    Columns: columns,
    Order: order.filter(el => columns.includes(el.Column)),
    Filter: filter,
  })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
